export var paymentMethod = {
  GOPAY: {
    id: 20,
    type: 'GOPAY'
  },
  BCA_VIRTUAL_ACCOUNT: {
    id: 22,
    type: 'VIRTUALACCOUNT'
  },
  LINKAJA: {
    id: 26,
    type: 'LINKAJA'
  },
  DANA: {
    id: 27,
    type: 'DANA'
  }
};