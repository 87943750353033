import React, {useState, useEffect} from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FacebookShareButton, WhatsappShareButton } from 'react-share'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'

import Upselling from './Upselling'
import {
  FlashMessage
} from '@3pp/ui-library'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'

import BcaInstruction from './BcaInstruction';
import MandiriInstruction from './MandiriInstruction';
import { eventTracker, fetchData, currencyFormatter } from '@3pp/utils'
import styles from './styles.module.scss'
import queryString from 'query-string'

dayjs.extend(LocalizedFormat)

const SummaaryPage = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [donationDetail, setDonationDetail] = useState([])
  const [showFlashMessage, setShowFlashMessage] = useState(false)

  const urlParams = queryString.parse(props.location.search)
  const onCopyText = () => setShowFlashMessage(true)
  const isUpselling = urlParams.utm_medium === 'kaiaccess_upselling_kbplus'

  const shareUrl = `${process.env.REACT_APP_BASE_URL}/campaign/${props.match.params.shortUrl}`

  useEffect(() => {
    let timeOut
    if (showFlashMessage) {
      timeOut = setTimeout(() => {
        setShowFlashMessage(false)
      }, 2000)
    }

    return () => clearTimeout(timeOut)
  }, [showFlashMessage])

  useEffect(() => {
    const fetchDonationDetail = async () => {
      try { 
        const donationDetailResponse = await fetchData({
          method: 'GET',
          url: `${process.env.REACT_APP_CORE_API}/mix_donations/${props.match.params.donationId}?open_code=${urlParams.open_code}`
        })
        setDonationDetail(donationDetailResponse.data[0])
        setIsLoading(false)
      } catch (error) {
        return window.location.href = `${process.env.REACT_APP_ERROR_URL}?from=${shareUrl}`
      }
    }
    fetchDonationDetail()
    //eslint-disable-next-line
  }, [])

  if (isLoading) return null

  const { campaign } = donationDetail.donations[0]
  const { invoice, payment_method: paymentMethod } = donationDetail

  const wAshareUrl = `${donationDetail.donations[0].campaign.title}. Bantu donasi dengan klik ${process.env.REACT_APP_BASE_URL}/campaign/${donationDetail.donations[0].campaign.short_url}?utm_source=whatsapp&utm_medium=socialsharing.`
  const fbShareUrl = `${process.env.REACT_APP_BASE_URL}/campaign/${donationDetail.donations[0].campaign.short_url}?utm_source=whatsapp&utm_medium=socialsharing.`

  return (
    <>
      <FlashMessage
        colorType="blue"
        inProp={showFlashMessage}
        text="Nomor Virtual Account berhasil disalin"
        icon={<FontAwesomeIcon icon={faCheckCircle} />}
      />

      {
        isUpselling ?
          (
            <Upselling
              donationDetail={donationDetail}
              onCopyText={onCopyText}
              {...props}
            />
          ) : (
            <>
              <section className={styles.instruction}>
                <p className={styles.instruction__title}>
                  Instruksi Pembayaran
                </p>
                <p className={styles.instruction__subTitle}>
                  Transfer sesuai nominal di bawah ini:
                </p>
                <p className={styles.instruction__amount}>
                  Rp {currencyFormatter(invoice.base_donation)}
                </p>
                <p className={styles.instruction__info}>
                  ke rekening <span> { paymentMethod.name }: </span>
                </p>
                <div className={styles.instruction__box}>
                  <img
                    src={paymentMethod.bank_logo}
                    alt=""
                  />
                  <h3>
                    {paymentMethod.bank_acc_nbr}
                  </h3>
                  <CopyToClipboard text={paymentMethod.bank_acc_nbr}>
                    <button
                      onClick={() =>
                        onCopyText('Nomor Virtual Account berhasil disalin')
                      }
                    >
                     SALIN
                    </button>
                  </CopyToClipboard>
                </div>
                <div className={styles.instruction__timebox}>
                  Transfer sebelum <strong>{dayjs.unix(donationDetail.donations[0].expired).format('DD MMM YYYY H:mm')} WIB</strong>{' '}
                  atau {campaign.is_zakat ? 'zakat' : 'donasi'} kamu otomatis dibatalkan oleh sistem..
                </div>
              </section>

            </>
          )
      }
      <div
        className={styles.customDivider}
      />
      <section className={styles.guide}>
        <p className={styles.guide__title}>
          Panduan Pembayaran
        </p>
        {
          (paymentMethod.bank_shortname === 'VABCA')
            ? (
              <BcaInstruction
                accountNumber={paymentMethod.bank_acc_nbr}
                {...props}
              />
            )
            : <MandiriInstruction />
        }
      </section>

      <div
        className={styles.customDivider}
      />
      <section className={styles.shareContent}>
        <p>
          Bantu share penggalangan dana ini
        </p>
        <div className={styles.shareButton}>
          <WhatsappShareButton
            url={wAshareUrl}
            onClick={() => {
              eventTracker(campaign.is_zakat ? 'button share wa zakat umma' : 'button share wa umma')
            }}
            className={styles.waShare}
          >
            <FontAwesomeIcon icon={faWhatsapp} /> Share
          </WhatsappShareButton>

          <FacebookShareButton
            url={fbShareUrl}
            onClick={() => {
              eventTracker(campaign.is_zakat ? 'button share fb zakat umma' : 'button share fb umma')
            }}
            className={styles.fbShare}
          >
            <FontAwesomeIcon icon={faFacebookSquare} /> Share
          </FacebookShareButton>

        </div>
        <div className={styles.back}>

          <button
            className={styles.back__btn}
            onClick={() => {
              props.history.push(`/campaign/${props.match.params.shortUrl}`)
            }}
          >
            Kembali ke penggalangan
          </button>

        </div>
      </section>
    </>
  )
}

export default SummaaryPage
