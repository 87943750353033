import React from 'react'

import imgDonationPending from '../assets/donationPending.png'
import styles from '../styles.module.scss'


const DonationPending = () => {
  return (
    <>
      <p className={styles.donation__status}>
        Pembayaran Sedang Diproses
      </p>
      <img 
        src={imgDonationPending}
        alt='donation-success'
        className={styles.donation__image}
      />
      <p className={styles.donation__infoAmount}>
        Silakan menunggu untuk kami verifikasi. Jika proses pembayaran kamu   gagal, silakan berdonasi kembali.
      </p>
    </>
  )
}

export default DonationPending
