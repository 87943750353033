import React from 'react'
import DonationStatusPage from 'components/DonationStatusPage'

const DonationStatus = (props) => {
  return (
    <DonationStatusPage {...props} />
  )
}

export default DonationStatus

