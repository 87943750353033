import React from 'react'
import ProgressBar from '../ProgressBar'
import { currencyFormatter } from '@3pp/utils'
import { Button } from '@3pp/ui-library'
import styles from './styles.module.scss'

const Card = ({
  title,
  image,
  progress=100,
  donationCollected=0,
  size='small',
  onClick=() => null,
  campaigner={
    name: '',
    verified: false,
    badge: 'https://assets.kitabisa.cc/images/icons/icon__verified-user.svg'
  },
  buttonColor='#E0004D',
  type='',
  campaignerName,
  isVerified,
  remainingDays,
  dataTestId
}) => {
  return (
    <div
      onClick={() => onClick()}
      className={styles[`card-${size}`]}
      data-testid={dataTestId}
    >
      <div className={styles[`card-${size}__image-container`]}>
        <img className={styles[`card-${size}__image`]} src={image} alt={title} />
      </div>
      <div className={styles[`card-${size}__meta`]}>
        <div className={styles[`card-${size}__meta-info`]}>
          <span className={styles[`card-${size}__title`]}>
            {title}
          </span>

          {
            (size === 'small' || size === 'fullwidth') && (
              <div className={styles[`card-${size}__campaigner`]}>
                <span>{campaignerName}</span>
                {
                  (isVerified) ?
                    <img src={campaigner.badge} alt="Verified" />
                    : null
                }
              </div>
            )
          }

          {
            ((size === 'small' || size === 'fullwidth' || 'medium') && type !== 'search') ?
              <ProgressBar
                percentage={progress}
              />
              : null
          }
          {
            type !== 'search' && size !== "fullwidth" && (
              <>
                <span className={styles[`card-${size}__title-collected`]}>
                  Terkumpul
                </span>
                <span className={styles[`card-${size}__donation-collected`]}>
                  Rp. {currencyFormatter(donationCollected)}
                </span>
              </>
            )
          }
          { size === 'fullwidth' &&
            (
              <div className={styles[`card-${size}__wrapper-collected`]}>
                <div className={styles[`card-${size}__left-collected`]}>
                  <span className={styles[`card-${size}__title-collected`]}>
                    Terkumpul
                  </span>
                  <span className={styles[`card-${size}__donation-collected`]}>
                    Rp. {currencyFormatter(donationCollected)}
                  </span>
                </div>
                <div className={styles[`card-${size}__right-collected`]}>
                  <span className={styles[`card-${size}__title-collected`]}>
                    Sisa Hari
                  </span>
                  <span className={styles[`card-${size}__donation-collected`]}>
                    {remainingDays}
                  </span>
                </div>
              </div>
            )
          }

          {
            type === 'search' && (
              <div className={styles[`card-${size}__campaigner`]}>
                <span>{campaigner.name}</span>
                {
                  (campaigner.verified) ?
                    <img src={campaigner.badge} alt="Verified" />
                    : null
                }
              </div>
            )
          }

        </div>
        <div className={styles[`card-${size}__action`]}>
          <Button
            key="donasi"
            borderRadius={4}
            backgroundColor={buttonColor}
            label="DONASI"
          />
        </div>
      </div>
    </div>
  )
}

export default Card
