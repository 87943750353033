import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { parse } from 'query-string'
import Home from 'containers/Home'
import Search from 'containers/Search'
import ExploreDetail from 'containers/ExploreDetail'
import Campaign from 'containers/Campaign'
import CampaignUpdate from 'containers/CampaignUpdate'
import Explore from 'containers/Explore'
import Contribute from 'containers/Contribute'
import Donations from 'containers/Donations'
import DonationStatus from 'containers/DonationStatus'
import { FailedPage } from '@3pp/ui-library'
import Summary from './containers/Summary'
import 'styles/main.scss'

const App = () => {
  let isKbplus: boolean
  return (
    <Router>
      <Switch>
        <Route path="/" exact render={(props) => (
          <Home {...props} />
        )} />
        <Route path="/search" render={(props) => (
          <Search {...props} />
        )} />
        <Route path="/explore/" exact render={(props) => (
          <Explore {...props} />
        )} />
        <Route path="/explore/:slug" render={(props) => (
          <ExploreDetail {...props} />
        )} />
        <Route path="/campaign/:slug" exact render={(props) => {
          isKbplus = props.match.params.slug === 'plus'
          return (
            isKbplus
              ? window.location.href = `${process.env.REACT_APP_KBPLUS_URL}?utm_source=3pp_halodoc`
              : <Campaign {...props} />
          )}} />
        <Route path="/campaign/:slug/:id/latest-news" exact render={(props) => (
          <CampaignUpdate {...props} />
        )} />
        <Route path="/campaign/:shortUrl/contribute" exact render={(props) => (
          <Contribute {...props} />
        )} />
        <Route path="/donations/:slug" exact render={(props) => (
          <Donations {...props} />
        )} />
        <Route path="/campaign/:shortUrl/contribute/summary/:donationId" exact render={(props) => (
          <Summary {...props} />
        )} />
        <Route path="/donation/status/:donationId" exact render={(props) => (
          <DonationStatus {...props} />
        )} />
        <Route path="/failed" exact render={(props) => (
          <FailedPage { ...parse(props.location.search) } />
        )} />
        <Route render={(props) => (
          <FailedPage 
            title="Halaman tidak ditemukan" 
            information="Saat ini kamu belum bisa mengakses halaman ini, kamu bisa kembali lagi nanti."
            from="/"
          />
        )} />
      </Switch>
    </Router>
  );
};

export default App;
