import styled from 'styled-components'

const Title = styled.div<{withMargin?: boolean}>`
  font-size: 16px;
  line-height: 22px;
  color: #4A4A4A;
  margin: ${props => props.withMargin ? '16px 0' : '0'};
  color: ${props => props.withMargin && '#4a4a4a'};
  font-size: ${props => props.withMargin && '18px'};
  font-weight: ${props => props.withMargin && '600'};
`
const Subtitle = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #989898;
  margin: 0;
`

export {
  Title,
  Subtitle
}
