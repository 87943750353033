/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import debounce from 'lodash.debounce'
import Card from 'components/common/Card'
import { stringify } from 'query-string'
import queryString from 'query-string'
import { MiniListLoader } from 'components/common/Loader'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { isEmpty } from '@3pp/utils'
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes'
import {
  Wrapper,
  InputContainer,
  TitleHeader,
  Input,
  SearchResultNotFound,
  SearchResultWrapper,
  CloseBtn
} from './styles'

interface State {
  title: string;
  short_url: string;
  id: number;
  image: string;
  donation_percentage: number;
  donation_received: number;
  campaigner?: string;
  campaigner_is_verified?: boolean;
  campaigner_badge?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SearchPage: React.SFC<{ initial?: State[]}> = (props: any, { initial = []}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [data, setData] = useState(initial)
  const [notFound, setNotFound] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const queryParams = queryString.parse(props.location.search)
  const stringifiedQuery = (!isEmpty(queryParams)) ? `?${stringify(queryParams)}` : ''

  const handleQuery = (keyword: any): void => {
    props.querySearch(keyword)
      .then((result: any) => {
        if (result.data.length > 0) {
          setData(result.data)
          setNotFound(false)
          setIsLoading(false)
        } else {
          setNotFound(true)
          setIsLoading(false)
        }
      })
  }
  const debounced = debounce(keyword => handleQuery(keyword), 500)

  useEffect(() => {
    if (queryParams.q) {
      handleQuery(queryParams.q)
      setSearchQuery(queryParams.q.toString())
    }
    //eslint-disable-next-line
  }, [])


  const handleKeyword = (e: { target: HTMLInputElement }): void =>  {
    if (e.target.value.length <= 2) return null
    return debounced(e.target.value)
  }

  const renderContent = (): JSX.Element | JSX.Element[] => {
    if (notFound) {
      return (
        <SearchResultNotFound>
          Campaign yang anda cari tidak dapat ditemukan
        </SearchResultNotFound>
      )
    }
    if (isLoading) return <MiniListLoader />
    return (
      <>
        {
          (data.length > 0) ?
            <TitleHeader>
              <span>Penggalangan</span>
            </TitleHeader>
            : null
        }
        {data.map((item: {
          campaigner: string;
          campaigner_is_verified: boolean;
          campaigner_badge: string;
          title: string;
          short_url: string;
          id: number;
          image: string;
          donation_percentage: number;
          donation_received: number;
        }) => {
          return (
            <Card
              onClick={(): Promise<boolean> => props.history.push(`/campaign/${item.short_url}${stringifiedQuery}`)}
              campaigner={{
                name: item.campaigner,
                verified: item.campaigner_is_verified,
                badge: item.campaigner_badge
              }}
              dataTestId=''
              remainingDays=''
              title={item.title}
              key={item.id}
              size="small--column"
              image={item.image}
              // progress={item.donation_percentage * 100}
              type="search"
              campaignerName=''
              isVerified={false}
              donationCollected={item.donation_received}
            />
          )
        })}
      </>
    )
  }
  return (
    <div>
      <InputContainer>
        <Wrapper>
          <CloseBtn
            onClick={(): Promise<boolean> => props.history.push(`/${stringifiedQuery}`)}>
            <FontAwesomeIcon  icon={faTimes} />
          </CloseBtn>
          <Input
            autoFocus
            defaultValue={searchQuery}
            onChange={handleKeyword}
            placeholder="Cari Penggalangan" type="text"
          />
        </Wrapper>
      </InputContainer>
      <SearchResultWrapper>
        {renderContent()}
      </SearchResultWrapper>
    </div>
  )
}

export default SearchPage
