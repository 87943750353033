import React from 'react'

import imgDonationFailed from '../assets/donationFailed.png'
import styles from '../styles.module.scss'


const DonationFailed = (props) => {
  return (
    <>
      <p className={styles.donation__status}>
        Pembayaran Gagal
      </p>
      <img
        src={imgDonationFailed}
        alt='donation-success'
        className={styles.donation__image}
      />
      <p className={styles.donation__infoAmount}>
        Donasi kamu belum tercatat di sistem kami. Silakan donasi lagi ke penggalangan <b>
        &quot;{props.campaignTitle}&quot;
        </b>
      </p>
    </>
  )
}

export default DonationFailed
