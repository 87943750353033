import styled from 'styled-components'

const Content = styled.div`
  max-width: 480px;
  width: 100%;
  margin: 29px auto 50px;
  padding-bottom: 5px;
`

export {
  Content
}
