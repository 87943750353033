import React from 'react'

import { currencyFormatter } from '@3pp/utils';

import imgDonationSuccess from '../assets/donationSuccess.png'
import styles from '../styles.module.scss'


const DonationSuccess = (props) => {

  if(!props.amount) return null
  return (
    <>
      <p className={styles.donation__status}>
        Pembayaran Berhasil
      </p>
      <img
        src={imgDonationSuccess}
        alt='donation-success'
        className={styles.donation__image}
      />
      <p className={styles.donation__infoAmount}>
        Donasimu sebesar <strong>  Rp {currencyFormatter(props.amount.base_donation)}</strong> <br /> telah kami salurkan
      </p>
    </>
  )
}

export default DonationSuccess
