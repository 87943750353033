import React from 'react'
import styles from '../styles.module.scss'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { currencyFormatter } from '@3pp/utils'
import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(LocalizedFormat)

const Upselling = (props) => {
  return (
    <>
      <section className={styles.instruction}>
        <p className={styles.instruction__title}>
          Instruksi Pembayaran
        </p>
        <p className={styles.instruction__subTitle}>
          Transfer sesuai nominal di bawah ini:
        </p>
        <p className={styles.instruction__amount}>
          Rp {currencyFormatter(props.donationDetail.invoice.base_donation)}
        </p>
        <p className={styles.instruction__info}>
          ke rekening <span> { props.donationDetail.payment_method.name }: </span>
        </p>
        <div className={styles.instruction__box}>
          <img
            src={props.donationDetail.payment_method.bank_logo}
            alt=""
          />
          <h3>
            {props.donationDetail.payment_method.bank_acc_nbr}
          </h3>
          <CopyToClipboard text={props.donationDetail.payment_method.bank_acc_nbr}>
            <button
              onClick={() =>
                props.onCopyText('Nomor Virtual Account berhasil disalin')
              }
            >
            SALIN
            </button>
          </CopyToClipboard>
        </div>
        <div className={styles.instruction__timebox}>
          Transfer sebelum <strong>{dayjs.unix(props.donationDetail.donations[0].expired).format('DD MMM YYYY H:mm')} WIB</strong>{' '}
          atau {props.donationDetail.donations[0].campaign.is_zakat ? 'zakat' : 'donasi'} kamu otomatis dibatalkan oleh sistem..
        </div>
      </section>
    </>
  )
}

export default Upselling
