import React from 'react'
import SummaryPage from 'components/SummaryPage'

const Summary = (props) => {
  return (
    <SummaryPage {...props} />
  )
}

export default Summary
