import React, {useState, useEffect} from 'react'

import Modal from 'react-modal'
import { fetchData } from '@3pp/utils'
import customHeaders from 'utils/customHeaders'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'
import { faTimes } from '@fortawesome/pro-regular-svg-icons/faTimes'

import styles from './styles.module.scss'

// Modal.setAppElement('#root')

const PaymentMethod = ({
  setSelectedPayment,
  selectedPayment
}) => {
  const [isOpen, setOpen] = useState(false)
  const [paymentList, setPaymentList] = useState([])

  useEffect(() => {
    const fetchPaymentList = async () => {
      const dataPayment = await fetchData({
        url: `${process.env.REACT_APP_SEKAWAN}/v1/auth/payment-method`,
        method: 'GET',
        additionalHeaders: customHeaders,
      })
      setPaymentList(dataPayment.data)

    }
    fetchPaymentList()
  }, [])

  const handleSelectedPayment = (payment) => {
    setSelectedPayment(payment)
    setOpen(false)
  }

  return (
    <section className={styles.paymentMethod__wrapper}>
      {
        selectedPayment.hasOwnProperty('image')
          ? (
            <>
              <img
                className={styles.paymentMethod__selectedImage}
                src={selectedPayment.image}
                alt=""
              />
              <span className={styles.paymentMethod__selectedName}>
                {selectedPayment.name}
              </span>
            </>
          )
          : (
            <span className={styles.paymentMethod__title}>
              Metode Pembayaran
            </span>
          )
      }
      <button
        className={styles.paymentMethod__button}
        onClick={() => setOpen(true)}
      >
        <span>
          {
            selectedPayment.hasOwnProperty('id') ? 'Ganti' : 'Pilih'
          }
        </span>
        <FontAwesomeIcon icon={faChevronDown} />
      </button>
      <Modal
        className={styles.paymentMethod__modal}
        overlayClassName={styles.paymentMethod__overlay}
        isOpen={isOpen}
        onAfterOpen={() => {}}
        onRequestClose={() => setOpen(false)}
      >
        <div
          className={styles.paymentMethod__iconClose}
          onClick={() => setOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <ul className={styles.paymentMethod__modalContent}>
          {
            paymentList.map((payment, index) =>
              (
                <li
                  key={index}
                  className={selectedPayment === payment ?  styles.paymentList__selected : styles.paymentList}
                  onClick={() => handleSelectedPayment(payment)}
                >
                  <img
                    className={styles.paymentList__img}
                    src={payment.image}
                    alt=""
                  />
                  <span className={styles.paymentList__title}>
                    {payment.name}
                  </span>
                </li>
              )
            )
          }
        </ul>
      </Modal>
    </section>
  )
}

export default PaymentMethod
