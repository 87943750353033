import React, { useState, Suspense} from 'react'
import Header from 'components/common/Header'
import Carousel from 'components/common/Carousel'
import Separator from 'components/Separator'
import NavbarBottom from 'components/NavbarBottom'
import Modal from 'react-modal'
import Sort from 'components/Sort'
import { eventTracker } from '@3pp/utils'
import { Content } from './styles'

const CarouselCampaign = React.lazy(() => import('./CarouselCampaign'))
const ListCampaign = React.lazy(() => import('./ListCampaign'))

Modal.setAppElement('#root')
interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router?: any;
  banners: [
    {
      id: string;
      image: string;
      url: string;
    }
  ];
  carouselCampaign: [
    {
      title: string;
      short_url: string;
      id: number;
      image: string;
      donation_percentage: number;
      donation_received: number;
    }
  ];
  listCampaign: [
    {
      title: string;
      short_url: string;
      id: number;
      image: string;
      donation_percentage: number;
      donation_received: number;
    }
  ];
}
const HomeComponent: React.SFC<Props> = (props) => {
  const [sortingOpen, setSortingOpen] = useState(false)
  const customStyles = {
    content : {
      top                   : '0',
      left                  : '0',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '0',
      width                 : '100%',
      zIndex: '1111'
    }
  }
  return (
    <div>
      <Modal
        className="Modal"
        overlayClassName="Overlay"
        isOpen={sortingOpen}
        onRequestClose={() => setSortingOpen(false)}
        style={customStyles}
      >
        <div style={{ width: '100%', height: '100%', background: '#f7f7f7' }}>
          <Sort
            {...props}
            onClose={() => setSortingOpen(false)}
          />
        </div>
      </Modal>
      <Header {...props} />
      <Carousel banners={props.banners} {...props} />
      <Content>
        <Suspense fallback={<div>Loading...</div>}>
          <CarouselCampaign carouselCampaign={props.carouselCampaign} {...props} />
        </Suspense>
        <Separator />
        <section>
          <Suspense fallback={<div>Loading...</div>}>
            <ListCampaign
              withMargin={false}
              listCampaign={props.listCampaign} {...props}
              title="#HalodocBerbagi"
              subtitle="Semangati mereka lawan penyakitnya dengan berbagi"
            />
          </Suspense>
        </section>
      </Content>
      <NavbarBottom
        {...props}
        onClickSorting={() => {
          eventTracker('sorting button halodoc')
          setSortingOpen(true)
        }}
      />
    </div>
  )
}

export default HomeComponent
