import React from 'react'
import { ListCard } from '@3pp/ui-library'
import { isEmpty, eventTracker } from '@3pp/utils'
import { stringify } from 'query-string'
import {
  Title,
  Subtitle
} from './styles'
import queryString from 'query-string'

const ListCampaign = (props) => {
  const queryParams = queryString.parse(props.location.search)
  const stringifiedQuery = (!isEmpty(queryParams)) ? `?${stringify(queryParams)}` : ''
  return (
    <>
      <Title withMargin={props.withMargin}>
        {
          (props.title) ?
            props.title
            :
            "Berbagi lagi, yuk!"
        }
      </Title>
      <Subtitle>
        {
          (props.subtitle) ?
            props.subtitle
            : ""
        }
      </Subtitle>
      <div style={{ marginTop: 20 }}>
        {
          (props.listCampaign !== null) ?
            props.listCampaign.map((listCampaignData) => {
              return (
                <ListCard
                  onClick={() => eventTracker('list campaign card halodoc')}
                  dataTestId="list card"
                  title={listCampaignData.title}
                  image={{
                    name: listCampaignData.title,
                    url: listCampaignData.image,
                  }}
                  shortUrl={listCampaignData.short_url}
                  key={listCampaignData.id}
                  campaigner={{
                    name: listCampaignData.campaigner,
                    type: listCampaignData.campaigner_type
                  }}
                  progressBar={{
                    color: 'primary',
                    percentage: listCampaignData.donation_percentage * 100
                  }}
                  progress={listCampaignData.donation_percentage * 100}
                  remainingDays={listCampaignData.days_remaining}
                  donationCollected={listCampaignData.donation_received}
                  permalink={`/campaign/${listCampaignData.short_url}${stringifiedQuery}`}
                  isVerified={listCampaignData.campaigner_is_verified}
                />
              )
            })
            : null
        }
      </div>
    </>
  )
}

export default ListCampaign
