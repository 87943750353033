/* eslint-disable react/display-name */
import React, {useEffect, useState} from 'react'

import {fetchData, eventTracker} from '@3pp/utils';
import switchCase from 'utils/switchCase';
import { getOpenCodeCookie } from 'utils/computeAssignmentForVisitorId';

import styles from './styles.module.scss'
import DonationSuccess from './DonationSuccess'
import DonationPending from './DonationPending'
import DonationFailed from './DonationFailed'

import { FacebookShareButton, WhatsappShareButton } from 'react-share'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons/faWhatsapp'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DonationStatusPage = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [donation, setDonation] = useState([])

  useEffect(() => {
    try {
      const fetchDonation = async () => {
        const donationId = props.match.params.donationId
        const openCode = getOpenCodeCookie(donationId)
        const donationResponse = await fetchData({
          method: 'GET',
          url: `${process.env.REACT_APP_CORE_API}/mix_donations/${donationId}?open_code=${openCode}`
        })
        setDonation(donationResponse.data[0])
        setIsLoading(false)
      }
      fetchDonation()
      //eslint-disable-next-line 
    } catch (error) {
      return window.location = `${process.env.REACT_APP_ERROR_URL}?from=${process.env.REACT_APP_BASE_URL}/campaign/${props.match.params.shortUrl}`
    }
  }, [props])

  if(isLoading) return null

  const { status, campaign } = donation.donations[0]
  const { invoice } = donation

  if(!campaign) return null

  const shareUrl = `${campaign.title}. Bantu donasi dengan klik ${process.env.REACT_APP_BASE_URL}/campaign/${campaign.short_url}?utm_source=whatsapp&utm_medium=socialsharing.`
  const statusType = status.status


  const paymentStatus = {
    PAID: () => <DonationSuccess amount={invoice} />,
    VERIFIED: () => <DonationSuccess amount={invoice} />,
    pending: () => <DonationPending />,
    CANCELLED: () => <DonationFailed campaignTitle={campaign.title} />,
  }

  return (
    <>
      <section className={styles.donation__wrapper}>
        {
          switchCase({
            cases: paymentStatus,
            defaultCase: paymentStatus['pending'],
            type: statusType
          })(donation)}
      </section>
      <div
        className={styles.customDivider}
      />
      <section className={styles.share}>
        <p className={styles.share__info}>
        Bantu <strong> {campaign.campaigner}  </strong> mencapai target donasi
        </p>
        {
          statusType !== 'CANCELLED' ? (
            <>
              <div className={styles.shareButton}
                beforeOnClick={(() => {
                  eventTracker(campaign.is_zakat ? 'button share fb zakat umma' : 'button share wa umma')
                })}
              >
                <FacebookShareButton
                  url={shareUrl}
                  className={styles.fbShare}
                >
                  <FontAwesomeIcon icon={faFacebookSquare} /> Bagikan ke Facebook
                </FacebookShareButton>
              </div>
              <div className={styles.shareButton}
                onClick={(() => {
                  eventTracker(campaign.is_zakat ? 'button share wa zakat umma' : 'button share wa umma')
                })}
              >
                <WhatsappShareButton
                  url={shareUrl}
                  className={styles.waShare}
                >
                  <FontAwesomeIcon icon={faWhatsapp} /> Bagikan ke Whatsapp
                </WhatsappShareButton>
              </div>
            </>
          ) : (
            <div className={styles.donate}>
              <button
                onClick={
                  () => props.history.push(`/campaign/${campaign.short_url}/contribute`)
                }
                className={styles.donate__btn}
              >
                Donasi Lagi
              </button>
            </div>
          )
        }
        <div className={styles.back}>
          <button
            onClick={
              () => props.history.push(`/campaign/${campaign.short_url}`)
            }
            className={styles.back__btn}
          >
            Kembali ke halaman galang dana
          </button>
        </div>
      </section>
    </>
  )
}

export default DonationStatusPage
