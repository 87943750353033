import React from 'react'
import {
  Container,
  Wrapper,
  SortContainer,
  IconNav,
  CategoryContainer,
  NavContainer,
  Inner,
  Outer,
} from './style'
import { eventTracker } from '@3pp/utils'
import sortingIcon from './assets/Sort.png'
import filterIcon from './assets/Filter.png'

export const NavbarBottom = props => {
  const onClickCategory = () => {
    eventTracker('category filter button halodoc')
    props.history.push(`/explore`)
  }
  return (
    <Container>
      <Wrapper>
        <NavContainer>
          <SortContainer
            onClick={() => props.onClickSorting()}
            data-testid='sorting'
          >
            <IconNav src={sortingIcon} alt="sort" />
            <p>Urutkan</p>
          </SortContainer>
          <Outer>
            <Inner />
          </Outer>
          <CategoryContainer
            onClick={() => onClickCategory()}
            data-testid='filter'
          >
            <IconNav src={filterIcon} alt="category" />
            <p>Kategori</p>
          </CategoryContainer>
        </NavContainer>
      </Wrapper>
    </Container>
  )
}

export default NavbarBottom
