import React from 'react'

import styles from '../styles.module.scss'

const BcaInstruction = (props) => {
  return (
    <div className={styles.guide__wrapper}>
      <p className={styles.guide__bankInfo}>BCA Mobile Banking (m-BCA) </p>
      <ol>
        <li>Masuk ke aplikasi mobile banking BCA, pilih m-Banking dan input PIN Anda.</li>
        <li>Pilih menu m-Transfer lalu BCA Virtual Account.</li>
        <li>Klik Input No Virtual Account dan masukkan nomor Virtual Account <strong> {props.accountNumber} </strong> lalu klik Ok.</li>
        <li>Cek nama dan nominal pembayaran, apabila telah sesuai klik Ok.</li>
        <li>Masukkan PIN m-BCA Anda dan klik Ok.</li>
        <li>Transaksi selesai, mohon simpan nomor invoice sebagai bukti pembayaran.</li>
      </ol>
    </div>
  )
}

export default BcaInstruction
