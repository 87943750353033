import React from 'react'

import styles from '../styles.module.scss'

const MandiriInstruction = (props) => {
  return (
    <div className={styles.guide__wrapper}>
      <p className={styles.guide__bankInfo}>Mandiri m-Banking (Mandiri Online) </p>
      <ol>
        <li>Login Mandiri Online dengan username dan password kamu.</li>
        <li>Pilih menu "Pembayaran", lalu pilih menu "Multipayment".</li>
        <li>Pilih penyedia jasa "Kitabisa"</li>
        <li>Masukkan "Nomor Virtual Account" dan "Nominal" yang akan dibayarkan, lalu pilih "Lanjut"</li>
        <li>Setelah muncul tagihan, pilih "Konfirmasi" dan masukkan PIN Mandiri Online.</li>
        <li>Transaksi selesai, simpan bukti bayar kamu.</li>
      </ol>
    </div>
  )
}

export default MandiriInstruction
