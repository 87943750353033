import React from 'react'
import styled from 'styled-components'

const Line = styled.div`
  margin: 0 -16px 20px;
  border-top: 10px solid #F2F3F4;
`
const Separator = () => (
  <Line />
)

export default Separator
